<template>
    <div class="bg-frontend-bg/75 flex flex-col md:flex-row gap-4 p-4 items-center justify-around w-fit md:w-full mx-auto mt-12 md:mt-16">
        <div class="flex flex-col sm:flex-row gap-4 justify-around w-full">
            <IndexDropdown 
                name="Manufacturer" 
                :options="manufacturers" 
                v-model:selected="selectedManufacturer" 
                v-model:is-done-scrolling="manufacturersDoneScrolling"
                :on-search="searchManufacturers"
                buttonStyle="homepage"
            />
            <IndexDropdown
                name="Model"
                :options="filteredModels"
                v-model:selected="selectedModel"
                v-model:is-done-scrolling="modelsDoneScrolling"
                :on-search="searchModels"
                buttonStyle="homepage"
            />
        </div>
        <Link :href="searchUrl" class="bg-frontend-brand text-white flex gap-3 w-48 px-5 items-center h-9 justify-center place-self-center mx-8">
            <svg class="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
            </svg>
            Search
        </Link>
    </div>
</template>

<script setup lang="ts">
import IndexDropdown from "@/Components/Frontend/Index/IndexDropdown.vue";
import {computed, onMounted, ref, watch} from "vue";
import {useFetch} from "@vueuse/core";
import type { Manufacturer, Model } from "@/util/model";
import { Link } from "@inertiajs/vue3";
interface Option {
    id: number | null;
    name: string;
}

const ALL_MANUFACTURERS: Option = { id: null, name: 'All Manufacturers' }
const ALL_MODELS: Option = { id: null, name: 'All Models' }


const selectedManufacturer = ref<Option>(ALL_MANUFACTURERS)
const selectedModel = ref<Option>(ALL_MODELS)

const manufacturers = ref<Manufacturer[]>([])
const models = ref<Model[]>([])

const modelsPage = ref(1)
const modelsLastPage = ref()
const modelsLoading = ref(false)
const isLastModelsPage = computed(() => modelsPage.value === modelsLastPage.value)
const modelsDoneScrolling = ref(false)

const manufacturersPage = ref(1)
const manufacturersLastPage = ref()
const manufacturersLoading = ref(false)
const isLastManufacturersPage = computed(() => manufacturersPage.value === manufacturersLastPage.value)
const manufacturersDoneScrolling = ref(false)

const searchUrl = computed(() => {
    let params: Record<string, any> = {}

    if (selectedManufacturer.value?.id !== null && selectedManufacturer.value?.id !== undefined) {
        params['manufacturers%5B%5D'] = selectedManufacturer.value.id
    }
    if (selectedModel.value?.id !== null && selectedModel.value?.id !== undefined) {
        params['models%5B%5D'] = selectedModel.value.id
    }
    return route('products.index', params)
})

const filteredModels = computed(() => {
    if (!selectedManufacturer.value?.id) return models.value
    return models.value.filter(model => model.manufacturer_id === selectedManufacturer.value.id)
})

// Search functions
const searchManufacturers = async (query: string) => {
    if (!query) {
        // If query is empty, reset to initial state
        manufacturersLoading.value = true
        const { data } = await useFetch(route('api.manufacturers')).get().json()
        if (data.value) {
            manufacturers.value = [ALL_MANUFACTURERS, ...data.value.data]
            manufacturersLastPage.value = data.value.meta.last_page
        }
        manufacturersLoading.value = false
        return
    }
    
    manufacturersLoading.value = true
    const params = { search: query }
    const { data } = await useFetch(route('api.manufacturers', params)).get().json()
    if (data.value) {
        manufacturers.value = [ALL_MANUFACTURERS, ...data.value.data]
        manufacturersLastPage.value = data.value.meta.last_page
    }
    manufacturersLoading.value = false
}

const searchModels = async (query: string) => {
    if (!query && !selectedManufacturer.value?.id) {
        // If no query and no manufacturer selected, reset to initial state
        modelsLoading.value = true
        const { data } = await useFetch(route('api.models')).get().json()
        if (data.value) {
            models.value = [ALL_MODELS, ...data.value.data]
            modelsLastPage.value = data.value.meta.last_page
        }
        modelsLoading.value = false
        return
    }
    
    modelsLoading.value = true
    const params: Record<string, any> = {}
    
    if (query) {
        params.search = query
    }
    
    if (selectedManufacturer.value?.id) {
        params.manufacturers = [selectedManufacturer.value.id]
    }
    
    const { data } = await useFetch(route('api.models', params)).get().json()
    if (data.value) {
        models.value = [ALL_MODELS, ...data.value.data]
        modelsLastPage.value = data.value.meta.last_page
    }
    modelsLoading.value = false
}

onMounted(async () => {
    manufacturersLoading.value = true
    modelsLoading.value = true

    const { data: manufacturersData } = await useFetch(route('api.manufacturers')).get().json()
    if (manufacturersData.value) {
        manufacturers.value = [ALL_MANUFACTURERS, ...manufacturersData.value.data]
        manufacturersLastPage.value = manufacturersData.value.meta.last_page
    }
    manufacturersLoading.value = false

    const { data: modelsData } = await useFetch(route('api.models')).get().json()
    if (modelsData.value) {
        models.value = [ALL_MODELS, ...modelsData.value.data]
        modelsLastPage.value = modelsData.value.meta.last_page
    }
    modelsLoading.value = false
})

watch(selectedManufacturer, () => {
    selectedModel.value = ALL_MODELS
})

watch(modelsDoneScrolling, async (value) => {
    if (value && !isLastModelsPage.value) {
        modelsLoading.value = true
        modelsPage.value++
        const params = selectedManufacturer.value?.id ? { manufacturers: [selectedManufacturer.value.id], page: modelsPage.value } : { page: modelsPage.value }
        const { data } = await useFetch(route('api.models', params)).get().json()
        if (data.value) {
            models.value = [...models.value, ...data.value.data]
        }
        modelsLoading.value = false
    }
})

watch(manufacturersDoneScrolling, async (value) => {
    if (value && !isLastManufacturersPage.value) {
        manufacturersLoading.value = true
        manufacturersPage.value++
        const { data } = await useFetch(route('api.manufacturers', { page: manufacturersPage.value })).get().json()
        if (data.value) {
            manufacturers.value = [...manufacturers.value, ...data.value.data]
        }
        manufacturersLoading.value = false
    }
})
</script>
