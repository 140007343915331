<template>
    <div class="relative text-nowrap inline-block text-left">
        <div class="flex justify-center">
            <button 
                @click="toggleDropdown" 
                :class="[
                    'inline-flex w-60 md:w-52 justify-between gap-x-1.5 rounded-sm px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-frontend-header',
                    buttonStyle === 'homepage' ? 'bg-frontend-bg text-white hover:bg-frontend-header/90' : 'bg-white text-gray-900 hover:bg-gray-100'
                ]"
            >
                <span class="block truncate text-sm">{{ selected ? selected.name : `Select ${name}` }}</span>
                <ChevronDown :class="buttonStyle === 'homepage' ? 'w-4 text-white' : 'w-4 text-frontend-header'" />
            </button>
        </div>
        
        <div v-if="isOpen" class="absolute right-0 z-10 w-60 md:w-52 origin-top-left bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="p-2 border-b border-gray-200">
                <div class="relative">
                    <input 
                        ref="searchInput"
                        type="text" 
                        v-model="searchQuery" 
                        class="w-full px-3 py-1 text-sm border border-gray-300 rounded-sm focus:outline-none focus:ring-1 focus:ring-frontend-header" 
                        :placeholder="`Search ${name}...`"
                        @input="handleSearch"
                        @keydown.esc="closeDropdown"
                        @keydown.down.prevent="focusNextItem"
                        @keydown.up.prevent="focusPreviousItem"
                        @keydown.enter.prevent="selectFocusedItem"
                    />
                    <div v-if="isSearching" class="absolute right-2 top-1/2 transform -translate-y-1/2">
                        <svg class="animate-spin h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                </div>
            </div>
            <div ref="container" class="overflow-y-auto overflow-x-hidden max-h-40">
                <div 
                    v-for="(option, index) in options" 
                    :key="option.id || index"
                    @click="selectOption(option)"
                    :class="[
                        'block px-4 py-2 text-sm text-black hover:text-white hover:bg-frontend-bg font-semibold cursor-pointer',
                        focusedIndex === index ? 'bg-frontend-bg text-white' : ''
                    ]"
                    @mouseover="focusedIndex = index"
                >
                    {{option.name}}
                </div>
                <div v-if="options.length === 0" class="px-4 py-2 text-sm text-gray-500 italic text-center">
                    No results found
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, nextTick, onMounted, onUnmounted } from "vue";
import ChevronDown from "@/Components/Frontend/Svg/ChevronDown.vue";
import { useDebounceFn } from '@vueuse/core';

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    options: {
        type: Array,
        required: true
    },
    selected: {
        type: Object,
        default: null
    },
    isDoneScrolling: {
        type: Boolean,
        default: false
    },
    onSearch: {
        type: Function,
        default: null
    },
    buttonStyle: {
        type: String,
        default: 'filters',
        validator: (value) => ['homepage', 'filters'].includes(value)
    }
});

const emits = defineEmits(['update:selected', 'update:isDoneScrolling', 'search']);
const selected = ref(props.selected);
const isDoneScrolling = ref(props.isDoneScrolling);
const container = ref();
const searchInput = ref();
const searchQuery = ref('');
const isSearching = ref(false);
const isOpen = ref(false);
const focusedIndex = ref(-1);
const originalOptions = ref([]);

// Store original options when component is mounted
onMounted(() => {
    if (props.options && Array.isArray(props.options)) {
        originalOptions.value = [...props.options];
    } else {
        originalOptions.value = [];
    }
});

// Watch for changes in options prop
watch(() => props.options, (newOptions) => {
    if (!isOpen.value) {
        if (newOptions && Array.isArray(newOptions)) {
            originalOptions.value = [...newOptions];
        } else {
            originalOptions.value = [];
        }
    }
});

// Watch for external changes to selected
watch(() => props.selected, (newVal) => {
    selected.value = newVal;
});

// Watch for external changes to isDoneScrolling
watch(() => props.isDoneScrolling, (newVal) => {
    isDoneScrolling.value = newVal;
});

// Emit changes to selected
watch(selected, (newVal) => {
    emits('update:selected', newVal);
});

// Emit changes to isDoneScrolling
watch(isDoneScrolling, (newVal) => {
    emits('update:isDoneScrolling', newVal);
});

// Debounce the search function to avoid too many API calls
const debouncedSearch = useDebounceFn((query) => {
    if (props.onSearch) {
        isSearching.value = true;
        props.onSearch(query)
            .finally(() => {
                isSearching.value = false;
            });
    }
}, 500);

const handleSearch = () => {
    debouncedSearch(searchQuery.value);
};

const selectOption = (option) => {
    selected.value = option;
    closeDropdown();
};

const focusNextItem = () => {
    if (focusedIndex.value < props.options.length - 1) {
        focusedIndex.value++;
    } else {
        focusedIndex.value = 0;
    }
};

const focusPreviousItem = () => {
    if (focusedIndex.value > 0) {
        focusedIndex.value--;
    } else {
        focusedIndex.value = props.options.length - 1;
    }
};

const selectFocusedItem = () => {
    if (focusedIndex.value >= 0 && focusedIndex.value < props.options.length) {
        selectOption(props.options[focusedIndex.value]);
    }
};

const toggleDropdown = () => {
    if (isOpen.value) {
        closeDropdown();
    } else {
        openDropdown();
    }
};

const openDropdown = () => {
    isOpen.value = true;
    nextTick(() => {
        searchInput.value?.focus();
    });
};

const closeDropdown = () => {
    isOpen.value = false;
    searchQuery.value = '';
    focusedIndex.value = -1;
    
    // Reset search results by calling onSearch with empty query
    if (props.onSearch) {
        props.onSearch('');
    }
};

// Watch for scroll events on the container
watch(isOpen, (newVal) => {
    if (newVal) {
        nextTick(() => {
            if (container.value) {
                container.value.addEventListener('scroll', handleScroll);
            }
        });
    } else {
        if (container.value) {
            container.value.removeEventListener('scroll', handleScroll);
        }
    }
});

const handleScroll = () => {
    if (!container.value) return;
    
    const { scrollTop, scrollHeight, clientHeight } = container.value;
    const isAtBottom = scrollHeight - scrollTop <= clientHeight + 10;
    
    if (isAtBottom && !isDoneScrolling.value) {
        isDoneScrolling.value = true;
    }
};

// Close dropdown when clicking outside
const handleClickOutside = (event) => {
    if (isOpen.value && !event.target.closest('.relative')) {
        closeDropdown();
    }
};

onMounted(() => {
    document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
    if (container.value) {
        container.value.removeEventListener('scroll', handleScroll);
    }
});
</script>
